<template>
  <div class="createForm">
    <div>
      <div class="bmyCard">
        <div class="bmyCardLogo"></div>
      </div>
      <div class="bmyContent">
        <div class="bmyContentTitle">
          <!-- <img class="logoImg" loading="lazy" src="@/assets/android-chrome-512x512_green.png" /> -->

          <h2 v-if="!resetMode">Login</h2>
          <h4 v-else>Forgot Password</h4>
        </div>
        <ILogin />
        <div class="bmyContentInputs">
          <div class="inputs">
            <vs-input
              :color="email !== '' ? 'success' : '#fff'"
              label="Email"
              placeholder="Email"
              v-model="email"
              id="email"
            >
              <template v-if="email === ''" #message-danger>
                Required
              </template>
            </vs-input>
          </div>
          <div v-if="!resetMode" class="inputs last">
            <vs-input
              id="password"
              type="password"
              :color="password !== '' ? 'success' : '#fff'"
              v-model="password"
              label="Password"
              label-placeholder="Password"
              :visiblePassword="hasVisiblePassword"
              icon-after
              @keydown.enter="onLogin"
              @click-icon="hasVisiblePassword = !hasVisiblePassword"
            >
              <template v-if="password === ''" #message-danger>
                Required
              </template>
            </vs-input>
          </div>
          <div class="extraInputs">
            <div class="flex" v-if="resetMode !== true">
              <vs-checkbox success v-model="remember">
                <template #icon>
                  <i class="fas fa-thumbs-up"></i>
                </template>
                Remember me</vs-checkbox
              >
            </div>
            <small>
              <a href="javascript:void(0);" @click="onResetMode">
                {{ this.resetMode ? "Go to LOGIN" : "Forgot Password?" }}
              </a>
            </small>
          </div>

          <vs-button
            :upload="email !== '' && password !== ''"
            block
            color="#038890"
            border
            v-on="{ click: resetMode ? onResetPassword : onLogin }"
            >{{ this.resetMode ? "RESET" : "LOGIN" }}</vs-button
          >
        </div>
        <div v-if="!resetMode" id="socialRegister">
          <div>
            <small> No tienes cuenta?</small>
            <a href="./register">
              Registrate!
            </a>
          </div>
          <div class="socialBtns">
            <vs-button @click.prevent="loginWithGoogle" icon color="google">
              <i class="fab fa-google"></i>
            </vs-button>
            <vs-button @click.prevent="loginWithFaceBook" icon color="facebook">
              <i class="fab fa-facebook"></i>
            </vs-button>
            <vs-button icon color="reddit">
              <i class="fab fa-instagram"></i>
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadingMix, noficationsDialog, imageMix, authMix } from "@/common/mixin.js";
import { ILogin } from "@/components/ILLUSTRATIONS";

export default {
  name: "Login",
  mixins: [loadingMix, noficationsDialog, imageMix, authMix],
  components: {
    ILogin
  },
  methods: {

    async loginWithFaceBook(){
      this.openLoading();
      try {
        const cred = await this.firebaseFaceBookAuth();
        await this.firebaseUpdateSubscriber(cred.user.uid);
        const resData = await this.firebaseGetUser(cred.user.uid);
        this.cred = cred.user.uid;
        resData.id = resData.id || cred.user.id;
        localStorage.setItem("user", resData.username);
        this.$store.commit("setUser", resData);
        const startLocation = this.$router.history._startLocation.toLocaleLowerCase();
        if (startLocation.includes("/event/")) {
          this.$router.back();
        } else if (startLocation.includes("/validate/")) {
          this.$router.push("/profile");
        } else {
          this.$router.push("/");
        }
        this.closeLoading();
      } catch (error) {
        console.log(error);
        this.notificateLoginFail(
          "Error con las Credenciales",
          "El usuario o la contraseña no es correcta"
        );
        this.closeLoading();
      }
    },

    async loginWithGoogle() {
      this.openLoading();
      try {
        const cred = await this.firebaseGoogleAuth();
        await this.firebaseUpdateSubscriber(cred.user.uid);
        const resData = await this.firebaseGetUser(cred.user.uid);
        this.cred = cred.user.uid;
        resData.id = resData.id || cred.user.id;
        localStorage.setItem("user", resData.username);
        this.$store.commit("setUser", resData);
        const startLocation = this.$router.history._startLocation.toLocaleLowerCase();
        if (startLocation.includes("/event/")) {
          this.$router.back();
        } else if (startLocation.includes("/validate/")) {
          this.$router.push("/profile");
        } else {
          this.$router.push("/");
        }
        this.closeLoading();
      } catch (error) {
        console.log(error);
        this.notificateLoginFail(
          "Error con las Credenciales",
          "El usuario o la contraseña no es correcta"
        );
        this.closeLoading();
      }
    },
    cleanInputMsg() {
      this.errorOnLogin = false;
    },

    onResetMode() {
      this.resetMode = !this.resetMode;
    },

    async onResetPassword() {
      this.openLoading();
      const isMail = /\S+@\S+\.\S+/.test(this.email);
      if (isMail) {
        await this.firebaseSendResetPassword(this.email);
        this.notificateLoginSuccess("Solicitud enviada", "Por favor revisa tu correo electrónico");
        this.closeLoading();
        this.$router.push("/login");
      }
      this.closeLoading();
    },

    async onLogin() {
      this.openLoading();
      try {
        const { email, password } = this;
        const cred = await this.firebaseLogin(email, password);
        await this.firebaseUpdateSubscriber(cred.user.uid);
        const resData = await this.firebaseGetUser(cred.user.uid);
        this.cred = cred.user.uid;
        resData.id = resData.id || cred.user.id;
        localStorage.setItem("user", resData.username);
        this.$store.commit("setUser", resData);
        const startLocation = this.$router.history._startLocation.toLocaleLowerCase();
        if (startLocation.includes("/event/")) {
          this.$router.back();
        } else if (startLocation.includes("/validate/")) {
          this.$router.push("/profile");
        } else {
          this.$router.push("/");
        }
        this.closeLoading();
      } catch (error) {
        console.log(error);
        this.notificateLoginFail(
          "Error con las Credenciales",
          "El usuario o la contraseña no es correcta"
        );
        this.closeLoading();
      }
    }
  },
  data() {
    return {
      email: "",
      password: "",
      newPassword: "",
      newPassword2: "",
      cred: null,
      hasVisiblePassword: false,
      errorOnLogin: false,
      remember: true,
      resetMode: false
    };
  }
};
</script>

<style scoped>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@600&display=swap");

.whiteLogo {
  top: 85px;
  width: 100%;
  position: relative;
  left: 0;
}

.inputs {
  justify-content: center;
  display: grid;
  margin-bottom: 1.5rem;
}

.inputs.last {
  margin-bottom: 0;
}

/* // Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */
@media (max-width: 575px) {
  .bmyCard {
    width: 100%;
    max-width: none !important;
  }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .bmyCard {
    width: 100%;
    max-width: none !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .header {
    margin: 5rem;
    margin-bottom: 3rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.previewImg {
  height: 50px;
  width: 50px;
}

.bmyCard {
  font-family: "Cairo", sans-serif;
  max-width: 500px;
  margin: auto;
  background: linear-gradient(0, #890303, #f83255);
  color: #038890;
  height: 10%;
  max-height: 150px;
  min-height: 20vh;
}

.bmyCardLogo {
  background-image: url("../assets/white_transparent.png");
  background-size: 100%;
  background-position-y: 50%;
  width: 100%;
  height: 40vh;
}

.bmyContent {
  color: #038890;
  max-width: 500px;
  margin: auto;
  background: white;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
}

.bmyContentTitle {
  display: flex;
  width: 100%;
  justify-content: center;
}

.bmyContentInputs {
  margin-top: 2rem;
}

.extraInputs {
  display: flex;
  justify-content: space-evenly;
}

.logoImg {
  height: 40px;
  width: 40px;
}

.socialBtns {
  display: flex;
}

#socialRegister {
  display: flex;
  justify-content: space-between;
}
</style>
